import * as Sentry from "@sentry/browser";

document.addEventListener("DOMContentLoaded", () => {
  const SentryDSN = document.querySelector('meta[name="sentry-dsn"]');

  if (SentryDSN) {
    Sentry.init({
      dsn: SentryDSN.content,
    });
  }
});
